<template>
  <h-card p0>
    <h4 class="text-center mb-4 pt-4">Player {{ player.name }}</h4>
    <b-row>
      <b-col>
        <p class="p-4">
          Choississez parmi nos solutions de diffusion
        </p>
        <b-tabs
          class="custom-tabs"
          content-class="mt-3"
          justified
          no-nav-style
          active-nav-item-class="item-custom-active"
        >
          <b-tab active>
            <template #title>
              <img
                :src="require(`@/assets/svg/players/webplayer.svg`)"
                width="30px"
                alt="webplayer"
              />
              <span class="d-none d-xl-inline ml-3">Streaming</span>
            </template>
            <p class="text-center">
              La diffusion peut se faire en streaming à l'aide du webplayer
              hubcast :
            </p>
            <p
              class="text-center"
              v-if="player.extended && player.extended.playerToken"
            >
              <a
                target="_blank"
                :href="
                  `https://play${isTest ? 'test' : ''}.radioshop.net/hubcast/` +
                    player.extended.playerToken
                "
              >
                <h-button blue>Ecouter via le webplayer hubcast</h-button>
              </a>
            </p>
          </b-tab>
          <b-tab>
            <template #title>
              <img
                :src="require(`@/assets/svg/players/application.svg`)"
                height="30px"
                alt="webplayer"
              />
              <span class="d-none d-xl-inline ml-3">Mobile/Tablette</span>
            </template>
            <p class="text-center">
              Le player est disponible sous iOS et Android :
            </p>
            <b-row class="app-store my-2">
              <b-col class="justify-content-center d-flex">
                <a
                  href="https://apps.apple.com/fr/app/hubcast-player/id1567598220"
                  target="_blank"
                >
                  <img src="@/assets/img/app-store.png" alt="apple store" />
                </a>
              </b-col>
              <b-col class="justify-content-center d-flex">
                <a
                  href="https://play.google.com/store/apps/details?id=net.radioshop.streamplayer"
                  target="_blank"
                >
                  <img src="@/assets/img/google-play.png" alt="google play"
                /></a>
              </b-col>
            </b-row>
            <p class="text-center">
              Installez l'application souhaitée et saisissez le code de player
              suivant :
            </p>
            <PlayersControlPairPlayerToken
              v-if="player.extended && player.extended.playerToken"
              :token="player.extended.playerToken"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <img
                :src="require(`@/assets/svg/players/windows.svg`)"
                width="30px"
                alt="webplayer"
              />
              <span class="d-none d-xl-inline ml-3">Windows</span>
            </template>
            <p class="text-center">
              Téléchargez et installez le player windows en cliquant sur le
              bouton ci-dessous.
            </p>
            <a
              href="http://st-communication.com/releases/windows/latest/setup_x64.exe"
              class="d-flex justify-content-center my-2"
            >
              <h-button blue>Télécharger le player 64 bits</h-button>
            </a>
            <p class="text-center mt-2">
              Saisissez ensuite le code player suivant lors de l'installation :
            </p>
            <PlayersControlPairPlayerToken
              v-if="player.extended && player.extended.playerToken"
              :token="player.extended.playerToken"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <img
                :src="require(`@/assets/svg/players/sonos2.svg`)"
                width="30px"
                alt="webplayer"
              />
              <span class="d-none d-xl-inline ml-3">SONOS</span>
            </template>
            <p class="text-center">
              Installez l’application Sonos et suivez les instructions :
              <br />1. Lancez l’app Sonos <br />2. Allez sur « Paramètres » puis
              « Services et voix » <br />3. Cliquez sur « + Ajouter un service »
              <br />4. Cliquez sur « Radioshop » puis « Ajouter à Sonos »
              <br />5. Saisissez le code de player suivant :
            </p>
            <PlayersControlPairPlayerToken
              v-if="player.extended && player.extended.playerToken"
              :token="player.extended.playerToken"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <img
                :src="require(`@/assets/svg/players/hardware.svg`)"
                width="25px"
                alt="webplayer"
              />
              <span class="d-none d-xl-inline ml-3">Player matériel</span>
            </template>
            <p class="text-center">
              Branchez et connectez à internet le player que vous avez reçu,<br />
              il apparaitra automatiquement dès qu’il aura été en contact avec
              nos serveurs.
            </p>
            <!-- <div class="d-flex justify-content-center mt-4">
              <h-button blue>Commander un boitier</h-button>
            </div> -->
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </h-card>
</template>

<script>
import PlayersControlPairPlayerToken from '@/components/player/PlayersControlPairPlayerToken'
export default {
  components: {
    PlayersControlPairPlayerToken,
  },
  data() {
    return {}
  },
  props: {
    player: {
      type: Object,
      componentLoaded: false,
      required: true,
    },
  },
  computed: {
    isTest() {
      return (
        window.location.host.includes('test') ||
        window.location.host.includes('local')
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.app-store {
  img {
    cursor: pointer;
    margin: 0 20px 20px 20px;
  }
}

a {
  text-decoration: none;
  color: white;
}
</style>
