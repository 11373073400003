<template>
  <h-card class="control-audio-card">
    <div class="on-top">
      <h4 class="justify-content-center align-items-center d-flex mb-4">
        <div>Player</div>
        <b-skeleton width="10%" height="30px" class="ml-3"></b-skeleton>
      </h4>
      <b-row class="on-top">
        <b-col>
          <div class="d-flex align-items-center">
            <div class="d-flex">
              <b-skeleton width="400px" height="25px"></b-skeleton>
            </div>
            <div class="ml-auto d-flex">
              <b-skeleton width="180px" height="44px"></b-skeleton>
              <b-skeleton width="135px" height="44px" class="ml-2"></b-skeleton>
            </div>
          </div>
          <div class="">
            <b-skeleton class="iframe-skeleton"> </b-skeleton>
          </div>
        </b-col>
      </b-row>
    </div>
  </h-card>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.iframe-skeleton {
  width: 100%;
  margin-top: 20px;
  height: 600px;
  border-radius: 10px;
}
</style>
