<template>
  <div
    class="trigger-item"
    @mouseover="hoverTrigger = true"
    @mouseleave="hoverTrigger = false"
    :class="{ 'is-playing': active }"
  >
    <b-row class="align-items-center row-triggers">
      <transition name="fade">
        <div class="media-container item-cover relative mr-2">
          <img
            src="@/assets/svg/default-message.svg"
            alt="cover image"
            class="media-type"
          />
          <div class="ico d-flex">
            <b-icon
              :icon="active ? 'pause-circle-fill' : 'play-circle-fill'"
              scale="1.5"
            ></b-icon>
          </div>
        </div>
      </transition>
      <b-col
        :cols="hoverTrigger ? 4 : 7"
        :sm="hoverTrigger ? 4 : 7"
        :md="hoverTrigger ? 7 : 9"
        :lg="hoverTrigger ? 5 : 8"
        :xl="hoverTrigger ? 7 : 9"
        class="text-uppercase flex-grow-1 pr-0"
      >
        <div class="w-100">
          <span class="name">
            {{ trigger.name }}
          </span>
        </div>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <h-button
          v-if="hoverTrigger"
          @click.native.stop="triggerItem()"
          transp
          orange
          :disabled="disableTrigger"
          class="no-white-space align-self-end"
        >
          <img
            src="@/assets/svg/trigger.svg"
            alt="trigger icon"
            class="mr-2 mb-1"
          />
          <span>Déclencher</span>
        </h-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import playerService from '@/services/playerService'
export default {
  data() {
    return {
      hoverTrigger: false,
      disableTrigger: false,
    }
  },
  props: {
    trigger: {
      type: Object,
      required: true,
    },
    player: {
      type: Object,
      required: true,
    },
    active: {
      Type: Boolean,
      default: false,
    },
  },
  methods: {
    async triggerItem() {
      const params = {
        IdSite: this.player.idPlayer,
        IdBrand: this.$store.state.user.user.visibleBrands[0].id,
        IdFile: this.trigger.idFile,
        FileName: this.trigger.name,
      }
      try {
        await playerService.sendTrigger(params)
        this.$toast.success(
          `${this.trigger.name} va être diffusé dans votre magain dans quelques secondes.`
        )
      } catch (e) {
        this.$toast.error(
          `Une erreur s'est produite, ${this.trigger.name} n'a pas pu être déclencher sur le player. Veuillez réessayer ultérieurement.`
        )
      } finally {
        this.disableTrigger = true
        setTimeout(() => {
          this.disableTrigger = false
        }, 10000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-cover {
  display: flex;
  align-items: center;
  width: 55px;
  height: 45px;
  padding-left: 15px;
  img {
    border-radius: 4px;
    width: 40px !important;
    height: 40px !important;
  }
}
.trigger-item {
  cursor: pointer;
  .media-container .ico {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &:hover,
  &:active,
  &.is-playing {
    background: #f8f9fa;
    color: $primary;
    font-weight: 600;
    .text-float {
      position: absolute;
      display: block;
      animation: floatText 11s linear infinite;
    }
    .media-type {
      filter: brightness(0.5);
    }
    .media-container .ico {
      opacity: 1;
    }
  }
  &:hover {
    .media-type {
      filter: brightness(0.5);
    }
  }
}
.row-triggers {
  flex-wrap: nowrap;
}
.trigger-item {
  padding: 6px 15px;
  color: $h-main-text;
  transition: 0.3s;
  .col {
    align-items: center;
  }
  &:hover,
  &:active {
    background: #f8f9fa;
    color: $primary;
    font-weight: 600;
    .text-float {
      position: absolute;
      display: block;
      animation: floatText 11s linear infinite;
    }
  }
  .time {
    font-size: 0.95rem;
    color: #848c9c;
    font-style: italic;
    white-space: nowrap;
  }
  .name {
    display: block;
    white-space: nowrap;
    max-width: 100%;
    height: 25px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
}
</style>
