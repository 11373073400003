import { render, staticRenderFns } from "./ModalUnpairConfirm.vue?vue&type=template&id=1ff0a0c5&scoped=true"
import script from "./ModalUnpairConfirm.vue?vue&type=script&lang=js"
export * from "./ModalUnpairConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff0a0c5",
  null
  
)

export default component.exports