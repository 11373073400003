<template>
  <div class="music-bars" :class="{ 'has-song-waves': displayBars }">
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
    <div class="clip-bar"></div>
  </div>
</template>

<script>
export default {
  props: {
    displayBars: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
