<template>
  <div class="d-flex justify-content-center">
    <div v-for="(tokenChar, index) in tokenFormat" :key="index">
      <div class="token-char">{{ tokenChar }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tokenFormat() {
      return this.token.split('')
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.token-char {
  background: #fbfbfb;
  padding: 3px 0;
  width: 50px;
  display: flex;
  justify-content: center;
  color: #323f5a;
  font-size: 2.2rem;
  font-weight: bold;
  margin: 3px;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(50, 63, 90, 0.15);
}

@media screen and (min-width: 576px) {
  .token-char {
    padding: 3px 0;
    width: 50px;
    font-size: 2.7rem;
  }
}

@media screen and (min-width: 768px) {
  .token-char {
    padding: 8px 0;
    width: 75px;
    font-size: 3.2rem;
    margin: 10px 8px;
  }
}
</style>
