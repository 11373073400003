<template>
  <div class="d-flex align-items-center" v-if="heartbeat">
    <div
      :class="statusIconMap(playerStatus()).color"
      class="d-flex status-ico mr-2"
    >
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <!-- <circle cx="50" cy="50" r="50" /> -->
        <circle
          class="zoomInOut"
          cx="100"
          cy="100"
          r="100"
          style="fill-opacity: .25;"
        />
        <circle cx="100" cy="100" r="55" />
      </svg>
    </div>
    <div v-if="withLabel" :class="statusIconMap(playerStatus()).color" class="">
      {{ statusIconMap(playerStatus()).label }}
      <template v-if="playerStatus().diff">
        {{
          playerStatus().index === 2
            ? `${
                $i18n.locale === 'fr'
                  ? `${$t('player.status.ago')} ${playerStatus().diff}`
                  : `${playerStatus().diff} ${$t('player.status.ago')}`
              } `
            : `${$t('player.status.for')} ${playerStatus().diff}`
        }}
      </template>
      <template v-if="player">
        <template v-if="player.lastIdApplication >= 0">
          <a
            class="current-playing-link"
            v-if="playerTypeText().link"
            :href="playerTypeText().link"
            target="_blank"
            >{{ playerTypeText().label }}</a
          >
        </template>
        <template v-else>{{ playerTypeText().label }}</template>

        <img
          v-if="
            (player.extended.idPairingApplication != undefined ||
              player.extended.idPairingApplication != null) &&
              player.extended.idPairingApplication !== 12
          "
          :src="
            require(`@/assets/svg/players/${playerTypeImg(
              player.extended.idPairingApplication
            )}.svg`)
          "
          alt="player type"
          class="player-type-ico ml-2"
          width="20px"
          height="20px"
        />
        <b-skeleton v-else width="42%" class="mt-1"></b-skeleton>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    heartbeat: String,
    withLabel: Boolean,
    connected: Boolean,
    player: Object,
  },
  computed: {
    isTest() {
      return (
        window.location.host.includes('test') ||
        window.location.host.includes('local')
      )
    },
  },
  methods: {
    playerStatus() {
      // display specific color and label depending heartbeat date
      var dt = moment(this.heartbeat)
      const dtNow = moment()
      if (dt.year() == '0001') {
        return {
          index: 0,
        }
      }
      if (this.connected) {
        return {
          index: 1,
        }
      } else if (dtNow.diff(dt, 'hours') <= 24) {
        if (dtNow.diff(dt, 'minutes') === 0) {
          return {
            index: 2,
            diff: parseInt(dtNow.diff(dt, 'secondes') / 1000) + ' secondes',
          }
        }
        if (dtNow.diff(dt, 'hours') < 2) {
          return {
            index: 2,
            diff:
              dtNow.diff(dt, 'minutes') +
              ' minute' +
              (dtNow.diff(dt, 'minutes') === 1 ? '' : 's'),
          }
        } else {
          return {
            index: 2,
            diff: dtNow.diff(dt, 'hours') + ' heures',
          }
        }
      } else if (dtNow.diff(dt, 'hours') <= 72) {
        return {
          index: 3,
          diff: dtNow.diff(dt, 'hours') + 'h',
        }
      } else {
        return {
          index: 4,
          diff: dtNow.diff(dt, 'days') + 'j',
        }
      }
    },
    statusIconMap(status) {
      const map = [
        {
          color: 'alert-color',
          label: this.$t('player.status.neverConnected'),
        },
        {
          color: 'green-color',
          label: this.$t('player.status.connected'),
        },
        {
          color: 'green-color',
          label: this.$t('player.status.seen'),
        },
        {
          color: 'warning-color',
          label: this.$t('player.status.disconnected'),
        },
        {
          color: 'alert-color',
          label: this.$t('player.status.disconnected'),
        },
      ]
      return map[status.index]
    },
    playerTypeImg(type) {
      switch (type) {
        case 1:
          return 'hardware'
        case 2:
          return 'hardware'
        case 3:
          return 'android'
        case 4:
          return 'ios'
        case 5:
          return 'sonos'
        case 6:
          return 'hardware'
        case 7:
          return 'webplayer'
        case 8:
          return 'webplayer'
        case 9:
          return 'hardware'
        case 11:
          return 'webplayer'
        case 12:
          return 'unknown'
        case 15:
          return 'windows'
        default:
          return 'unknown'
      }
    },
    playerTypeText() {
      switch (this.player.extended.idPairingApplication) {
        case 1:
          return { label: 'sur boitier', link: null }
        case 2:
          return { label: 'sur boitier', link: null }
        case 3:
          return {
            label: 'sur Android',
            link:
              'https://play.google.com/store/apps/details?id=net.radioshop.streamplayer',
          }
        case 4:
          return {
            label: 'sur iOS',
            link: 'https://apps.apple.com/fr/app/hubcast-player/id1567598220',
          }
        case 5:
          return {
            label: this.$t('player.playerConnected.onSonos'),
            link: null,
          }
        case 6:
          return { label: this.$t('player.playerConnected.onCase'), link: null }
        case 7:
          return {
            label: this.$t('player.playerConnected.onExtranet'),
            link: null,
          }
        case 8:
          return {
            label: this.$t('player.playerConnected.onMyHubcast'),
            link: null,
          }
        case 9:
          return { label: this.$t('player.playerConnected.onCase'), link: null }
        case 11:
          return {
            label: this.$t('player.playerConnected.onWebplayer'),
            link: `https://play${
              this.isTest ? 'test' : ''
            }.radioshop.net/hubcast/play.html?code=${
              this.player.extended.playerToken
            }`,
          }
        case 12:
          return { label: '', link: null }
        case 15:
          return {
            label: this.$t('player.playerConnected.onWindows'),
            link: null,
          }
        default:
          return { label: '', link: null }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.current-playing-link {
  color: inherit;
  text-decoration: underline;
}
.green-color {
  color: #51ed37;
}
.warning-color {
  color: #f0a45d;
}
.alert-color {
  color: #ea4e4e;
}
.status-ico svg {
  width: 100%;
}
.status-ico {
  width: 20px;
  height: 20px;
  &.green-color {
    svg {
      fill: #51ed37;
    }
  }
  &.warning-color {
    svg {
      fill: #f0a45d;
    }
  }
  &.alert-color {
    svg {
      fill: #ea4e4e;
    }
  }
}
.zoomInOut {
  -webkit-animation-name: zoomInOut;
  animation-name: zoomInOut;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  transform-origin: center;
}
@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes zoomInOut {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
</style>
