<template>
  <b-modal v-model="modalShow" hide-header hide-footer centered>
    <div class="px-2">
      <div class="d-block text-center">
        <h4>
          {{ $t('player.confirmUnpair') }}
        </h4>
      </div>
      <div class="my-5">
        <p class="lead large text-center">
          {{ $t('player.warningMessageUnpair1') }}
          <b>{{ playerTypeText() }}</b
          >{{ $t('player.warningMessageUnpair2') }}
        </p>
        <p class="lead large text-center">
          {{ $t('player.unpairConfirmMessage') }}
          <b>{{ $t('player.thePlayer') }} {{ player.name }}</b> ?
        </p>
      </div>
      <div class="mt-1 mb-3 d-flex flex-column">
        <div class="d-flex justify-content-center">
          <h-button @click="close" outline red class="px-3 mr-3">
            {{ $t('actions.no') }}
          </h-button>
          <h-button @click="unPairPlayer" green class="px-3 mr-3">
            {{ $t('actions.yes') }}
          </h-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import playerService from '@/services/playerService'
export default {
  props: {
    showUnpairConfirm: Boolean,
    player: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      changeFluxLoading: false,
    }
  },
  methods: {
    playerTypeText() {
      switch (this.player.extended.idPairingApplication) {
        case 1:
          return this.$t('player.playerType.linux')
        case 2:
          return this.$t('player.playerType.linux')
        case 3:
          return this.$t('player.playerType.androidApp')
        case 4:
          return this.$t('player.playerType.iosApp')
        case 5:
          return this.$t('player.playerType.sonos')
        case 6:
          return this.$t('player.playerType.linux')
        case 7:
          return this.$t('player.playerType.extranet')
        case 8:
          return this.$t('player.playerType.myHubcast')
        case 9:
          return this.$t('player.playerType.linux')
        case 11:
          return this.$t('player.playerType.webplayer')
        case 12:
          return { label: '', link: null }
        case 15:
          return this.$t('player.playerType.windowsPlayer')
        default:
          return { label: '', link: null }
      }
    },
    unPairPlayer() {
      try {
        playerService.unpairPlayer(this.player.idPlayer)
        this.$emit('unpair')
        this.$emit('close')
      } catch (e) {
        this.$toast.error(
          "Une erreur s'est produite sur le désappairage, veuillez réessayer ultérieurement"
        )
        this.$emit('close')
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped></style>
